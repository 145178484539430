<template>
    <div>
      
      <div class="grid-container --margin-left --margin-top">
          <div class="flex-container">
      
            <div>Modifier les points des couronnes de la cible</div>
        
            <p v-if="error">{{ error }}</p>
            <button v-if="!loading" @click="cancel_btn">Annuler</button>
            <button v-if="loading" disabled>...</button>
          
          </div>
          
          <div>Couronnes</div>
          
          <div class="flex-container">
            <form @submit="saveNewDistances">
              <table class="table table-bordered table-wrap">
                <tr>
                  <th scope="col">Rayon (m)</th>
                  <th scope="col">Points</th>
                </tr>
                <tr>
                  <th>200</th>
                  <th><input type="text" :placeholder="pointsDistance[0]" name="name" v-model="pointsDistanceLocal[0]" required></th>
                </tr>
                <tr>
                  <th>100</th>
                  <th><input type="text" :placeholder="pointsDistance[1]" name="name" v-model="pointsDistanceLocal[1]" required></th>
                </tr>
                <tr>
                  <th>50</th>
                  <th><input type="text" :placeholder="pointsDistance[2]" name="name" v-model="pointsDistanceLocal[2]" required></th>
                </tr>
                <tr>
                  <th>20</th>
                  <th><input type="text" :placeholder="pointsDistance[3]" name="name" v-model="pointsDistanceLocal[3]" required></th>
                </tr>
              </table>
  
              <button type="submit">Sauvegarder</button>  
            </form>
          </div>

      </div>

    </div>
  </template>
  
  <style scoped>
  
  .table-wrap{
    word-wrap: break-word;
    max-width: 10rem;
    max-height: 6rem;
  }

  input{
    max-height: 2em;
    max-width: 5em;
    text-align: center;
  }
  
  button{
    max-height: 2em;
    border-radius: .75rem;
    margin-left: .25rem;
  }
  
  .debug{
    outline: red solid 4px;
  }
  .flex-container{
    display: flex;
    gap: 2em; 
    flex-wrap: wrap;
    align-items: center;
  }
  .input-items{
    display: flex;
    gap: .5em;
  }
  
  .grid-container{
    display: grid;
    gap: 1em;
    margin-bottom: 2em;
  }
  
  .small-gap{
    gap: 1em;
  }
  
  .--margin-left{
    margin-left: 2em;
  }
  .--margin-top{
    margin-top: 2em;
  }
  
  </style>
  
  <script>

  import { mapState } from 'vuex';
  export default {
    name: 'PointsModify',
    components: {
    },
    data () {
      return {
        pointsDistanceLocal: [6, 7, 8, 10],
        selected: '',
        step: "title",
  
        loading: false,
        error: '',
  
        quizzData: {
          unique_name: null,
  
          name: null,
          description: null,
  
          questions: []
        },
  
        titleForm: {
          name: null,
          description: null
        },
  
        newQuestionForm: {
          url: null,
          embeddedUrl: null,
          file: null,
  
          preview_url: null,
  
          name: null,
          coords: null,
  
          coords_validated: null,
  
          progress: 0
        },
      }
    },
    computed: {
      ...mapState(['pointsDistance'])
    },
    mounted(){
      this.pointsDistanceLocal = this.pointsDistance
    },
    props: {
    },
    methods: {
      saveNewDistances(e){
        e.preventDefault();

        let floatDistances = this.pointsDistanceLocal.map(e => {
          return parseFloat(e);
        });
        this.pointsDistanceLocal = floatDistances;
        console.log("PointsDistance", this.pointsDistanceLocal);

        this.$store.commit("updatePoints", this.pointsDistanceLocal);


        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            'Authorization': `Bearer ${this.$store.state.login_token}`,
            },
          body: JSON.stringify({
            circlesPoints: this.pointsDistanceLocal
          })
        };

        var self = this;
  
        fetch(this.$store.state.serverIp + '/api/pres/pointsModify/', requestOptions)
        .then(async function (response) {
  
          self.loading = false;
  
          switch (response.status) {
            case 200:
              console.log("Operation Succeeded");
              break;
            case 401:
              self.error = "Non authentifié";
              break;
            case 403:
              self.error = "Authentification incorrecte";
              break;
            default:
              self.error = "Une erreur est survenue au niveau du serveur";
              break;
          }
        });
        this.$emit('close');

        // ('/api/pres/pointsModify/
      },
      submit_titleForm(e) {
        e.preventDefault();
        
        this.loading = true;
        this.error = '';
  
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            'Authorization': `Bearer ${this.$store.state.login_token}`,
            },
          body: JSON.stringify({
            name: this.titleForm.name,
            description: this.titleForm.description
          })
        };
  
        var self = this;
  
        fetch(this.$store.state.serverIp + '/api/pres/quizz/init', requestOptions)
        .then(async function (response) {
  
          self.loading = false;
  
          switch (response.status) {
            case 201:
              var unique_name = await response.text();
  
              self.quizzData.name = self.titleForm.name;
              self.quizzData.description = self.titleForm.description;
              self.quizzData.unique_name = unique_name;
  
              self.step = 'questions'
  
              break;
            case 401:
              self.error = "Non authentifié";
              break;
            case 403:
              self.error = "Authentification incorrecte";
              break;
            default:
              self.error = "Une erreur est survenue au niveau du serveur";
              break;
          }
        });
  
      },
  
    
  
      cancel_btn(e) {
        e.preventDefault();
  
        this.$emit('close');
      }
    }
  }
  </script>