<template>
  <div>

    <div v-if="step=='initQuizz'">

      <div ref="mapRef" style="height: 500px; width: 500px;"></div>

      <div v-if="initQuizz_step>=1">
        Centre
        <button @click="setCenter" v-if="clickMarkerSET && initQuizz_step==1">Définir</button>
        <button @click="re_setCenter" v-if="clickMarkerSET && initQuizz_step>1">Redéfinir</button>
        <button v-if="!clickMarkerSET || initQuizz_step!=1" disabled>Définir</button>
        <div v-if="init.center">
          OK ! ({{init.center}})
          <button @click="testCenter">Tester</button>
          <button @click="unsetCenter">Supprimer</button>
        </div>
      </div>

      <div v-if="initQuizz_step>=2">
        Frontières
        <button @click="initBounds" v-if="initQuizz_step==2 && !init_boundsRectangleSET">Commencer la selection</button>
        <button @click="initBounds" v-if="initQuizz_step==2 && init_boundsRectangleSET">Réinitialiser la selection</button>
        <button @click="resetBounds" v-if="initQuizz_step==2 && init_boundsRectangleSET">Annuler la selection</button>

        <button @click="setBounds" v-if="initQuizz_step==2 && init_boundsRectangleSET">Confirmer la selection</button>
        <button v-if="initQuizz_step!=2" disabled>Confirmer la selection</button>

        <div v-if="init.bounds">
          OK ! ({{init.bounds}})
          <button v-if="!init_testingBounds" @click="testBounds">Tester (définis une frontière pendant 5 secondes)</button>
          <button v-if="init_testingBounds" disabled>Tester (définis une frontière pendant 5 secondes)</button>
          <button @click="unsetBounds">Supprimer</button>
        </div>
      </div>

      <div v-if="initQuizz_step>=3">
        Zoom
        <button @click="setZoom" v-if="initQuizz_step==3">Définir</button>
        <button v-if="initQuizz_step!=3" disabled>Définir</button>
        <div v-if="init.zoom">
          OK ! ({{init.zoom}})
          <button @click="testZoom">Tester</button>
          <button @click="unsetZoom">Supprimer</button>
        </div>
      </div>

      <div v-if="initQuizz_step>=4">
        <button @click="finishInit">Confirmer ces paramètres</button>
      </div>

      <hr>

    </div>

    <div v-if="step=='newQuestion'">
      <div ref="mapRef" style="height: 200px; width: 200px;"></div>
    </div>

  </div>
  
</template>

<script>

var clickMarker;

var center_marker;

var bounds_rectangle;

export default {
  name: 'CreationMap',
  components: {
  },
  data () {
    return {
      latLng: null,
      map: null,

      clickEvent_enabled : true,
      clickMarkerSET: false,

      init: {
        center: null,
        zoom: null,
        bounds: null
      },
      initQuizz_step: 1,
      init_boundsRectangleSET: false,
      init_testingBounds: false,

    }
  },
  props: {
    google: Object,
    step: String,
    pin: Object,
    questions: Array,
  },
  // computed: {
  //   lat() {
  //     if (this.latLng) {
  //       return this.latLng.lat();
  //     } else {
  //       return null
  //     }
  //   },
  //   lng() {
  //     if (this.latLng) {
  //       return this.latLng.lng();
  //     } else {
  //       return null
  //     }
  //   },
  // },
  async mounted() {
    const google = this.google;

    if (this.step == 'initQuizz') {   

      const map = new google.maps.Map(this.$refs.mapRef, {
        // center: { lat: 46.65457045415254, lng: 2.5068183781379907},
        // zoom: 6,

        center: this.questions[0].coords,
        zoom: 12,

        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      });

      this.questions.forEach(q => {
        new google.maps.Marker({
          position: q.coords,
          map,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
          }
        });
      });

      this.map = map;

      var self = this;
      map.addListener("click", function(e) {

        if (self.clickEvent_enabled) {
          if (self.clickMarkerSET) {
            clickMarker.setMap(null);
          }
          
          clickMarker = new google.maps.Marker({
            position: e.latLng,
            map,
          });

          self.latLng = e.latLng;
          self.clickMarkerSET = true;
        }

        
      });

    } else if (this.step == 'newQuestion') {

      const coords = this.pin

      const map = new google.maps.Map(this.$refs.mapRef, {
        center: coords,
        zoom: 12,

        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      });

      new google.maps.Marker({
        position: coords,
        map,
      });

    }

  },
  methods: {
    drawCircle(center, radius) {
      const google = this.google;
      const map = this.map;

      new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map,
        center: center,
        radius: radius,
      });
    },

    drawRectangle(north, south, east, west) {
      const google = this.google;
      const map = this.map;

      return new google.maps.Rectangle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map,
        bounds: {
          north: north,
          south: south,
          east: east,
          west: west,
        },
      });
    },

    // pin(position, label,) {
    //   const google = this.google;
    //   const map = this.map;

    //   new google.maps.Marker({
    //     position,
    //     map,
    //     // title: `${i + 1}. ${title}`,
    //     label: label,
    //     optimized: false,
    //   });
 
      
    // },

    /***
     * INIT QUIZZ
     */


    // CENTER
    setCenter(e) {
      e.preventDefault();

      // Stocke latLng du center
      this.init.center = {
        lat: this.latLng.lat(),
        lng: this.latLng.lng()
      };

      // Passe à l'étape suivante de l'init
      this.initQuizz_step = 2;

      // Enlève le marker du click event
      clickMarker.setMap(null);
      this.clickMarkerSET = false;

      // met un marker au centre
      const google = this.google;
      const map = this.map;

      center_marker = new google.maps.Marker({
        position: this.init.center,
        map,
        label: 'Centre'
      });

      // update la map : centrer et zoom
      this.updateMap_Center()
    },

    re_setCenter(e) {
      this.unsetCenter(e);
      this.setCenter(e);
    },

    unsetCenter(e) {
      e.preventDefault();

      this.init.center = null;
      this.init.bounds = null;
      this.init.zoom = null;

      this.initQuizz_step = 1;
      this.clickEvent_enabled = true;

      this.removeMarker_Center();
      this.removeBounds_Rectangle();
    },

    removeMarker_Center() {
      if (typeof center_marker !== 'undefined') center_marker.setMap(null);
    },

    testCenter(e) {
      e.preventDefault();

      this.updateMap_Center();
    },

    updateMap_Center() {
      this.map.setCenter(this.init.center);
      this.map.setZoom(10);
    },


    // BOUNDS
    initBounds(e) {
      e.preventDefault();

      const google = this.google;
      const map = this.map;

      this.removeBounds_Rectangle();

      // Enlève le marker du click event
      clickMarker.setMap(null);
      this.clickMarkerSET = false;

      this.clickEvent_enabled = false;
      this.init_boundsRectangleSET = true;

      bounds_rectangle = new google.maps.Rectangle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.1,
        map,
        editable: true,
        bounds: {
          north: this.init.center.lat + 0.05,
          south: this.init.center.lat - 0.05,
          east: this.init.center.lng + 0.07,
          west: this.init.center.lng - 0.07,
        },
      });
    },

    resetBounds() {
      this.clickEvent_enabled = true;
      this.removeBounds_Rectangle();
    },

    setBounds(e) {
      e.preventDefault();

      var bounds = bounds_rectangle.getBounds();
      var NE = bounds.getNorthEast();
      var SW = bounds.getSouthWest();

      this.init.bounds = {
        north: NE.lat(),
        east: NE.lng(),
        south: SW.lat(),
        west: SW.lng()
      };

      bounds_rectangle.setEditable(false);

      this.initQuizz_step = 3;
    },

    unsetBounds(e) {
      e.preventDefault();

      this.init.bounds = null;
      this.init.zoom = null;

      this.initQuizz_step = 2;

      this.removeBounds_Rectangle();     
    },

    removeBounds_Rectangle() {
      if (typeof bounds_rectangle !== 'undefined') bounds_rectangle.setMap(null);
      this.init_boundsRectangleSET = false;
    },

    testBounds(e) {
      e.preventDefault();

      this.updateMap_Center();
      this.map.setOptions({
        restriction: {
          latLngBounds: this.init.bounds
        }
      });

      this.init_testingBounds = true;

      var self = this;
      setTimeout(function () {
        self.map.setOptions({
          restriction: null
        });
        self.init_testingBounds = false;
      }, 5000);
    },

    // ZOOM
    setZoom(e) {
      e.preventDefault()

      this.initQuizz_step = 4;

      const map = this.map;
      this.init.zoom = map.getZoom();

      
    },

    testZoom(e) {
      e.preventDefault()
      const map = this.map;
      map.setZoom(this.init.zoom)
    },

    unsetZoom(e) {
      e.preventDefault()
      this.init.zoom = null;
      this.initQuizz_step = 3;
    },

    // FINISH
    finishInit(e) {
      e.preventDefault();

      this.$emit('finishInit', this.init);
    }
  }
}
</script>