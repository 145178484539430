<template>
  <div style="width: 100%;">
    <p v-if="error">{{ error }}</p>
    <form @submit="joinRoomForm" style="width: 100%;">

      <div class="input-group" style="margin-bottom: 15px;">
        <input class="form-control" style="text-align: center;" type="number" placeholder="Code de la partie" name="roomID" v-model="roomID" required>
      </div>

      <button v-if="!loading" class="btn btn-primary" type="submit" style="width: 100%;">
        Rejoindre
      </button>
      <button v-if="loading" class="btn btn-primary" type="submit" style="width: 100%;" disabled>
        <span class="spinner-grow spinner-grow-sm textinfo text-info" role="status"></span>
      </button>
    </form>

    
  </div>
</template>

<script>
export default {
  name: 'JoinRoom',
  data () {
    return {
      loading: false,
      error: null,

      roomID: null,
      pseudo: null,
    }
  },
  methods: {
    joinRoomForm(e) {
      e.preventDefault();

      this.loading = true;
      this.error = '';

      const requestOptions = {
        method: "GET",
      };

      var self = this;

      fetch(this.$store.state.serverIp + '/api/game/check/' + this.roomID, requestOptions)
      .then(async function (response) {

        self.loading = false;

        switch (response.status) {
          case 200:
            // var body = await response.json();
            // self.$emit('login', body.jwt)
            console.log('room joinable');

            self.$store.commit('JOIN_ROOM', {
              roomID: self.roomID,
              // pseudo: self.pseudo
            });

            self.$router.push('/play/' + self.roomID);

            break;
          case 400:
            self.error = "Tout les champs sont obligatoires";
            break;
          case 404:
            self.error = "Cette salle n'existe pas ou n'est pas accessible";
            break;
          default:
            self.error = "Une erreur est survenue au niveau du serveur";
            break;
        }
      });
    }
  }
}
</script>
