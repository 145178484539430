<template>
  <div style="height: 100%;">

    <div v-if="step=='loading'">...</div>

    <div v-if="step=='lobby'" style="height: 100%;">

      <!-- <div class="d-flex flex-column" style="height: 100%;">
        <div class="row flex-grow-1" style="margin: 0px;height: 50%;">
          <div class="col d-lg-flex flex-column justify-content-lg-center align-items-lg-center">
            <h1 class="text-center">Enquête d'images</h1>
            <div class="border rounded border-primary d-lg-flex flex-column justify-content-lg-center align-items-lg-center" style="margin-top: 30px;width: 100%;">
              <h2>{{quizzData.name}}</h2>
              <p>{{quizzData.description}}</p>
            </div>
          </div>
          <div class="col d-lg-flex flex-column justify-content-lg-center align-items-lg-center" style="padding-right: 30px;padding-left: 30px;">
            <canvas ref="qrcode_canvas"></canvas>
            <div class="text-center text-dark bg-light border rounded border-light" style="width: 100%;margin-top: 20px;">
              <h2>Code de la partie</h2>
              <h1>{{roomID_display}}</h1>
              <h5 class="text-secondary" style="font-style: italic;">play.sunomad.fr</h5>
            </div>
          </div>
        </div>
        <div class="row text-center flex-grow-1" style="margin: 0px;height: 50%;">
          <div class="col d-flex flex-column">
            <div class="row">
              <div class="col">
                <h2>Joueurs ({{playerCount}}/{{maxPlayers}})</h2>
              </div>
            </div>
            <div class="row flex-grow-1">
              <div class="col d-flex align-content-start flex-wrap justify-content-lg-center align-items-lg-start">
                <div v-for="player in playerList" :key="player.ID" class="border rounded border-secondary" style="margin: 10px;">
                  <p style="margin: 5px 10px;">{{player.pseudo}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button @click="startGame" class="btn btn-primary" type="button" style="margin-bottom: 10px;width: 100%;">Lancer la partie</button>
              </div>
            </div>
          </div>
          <div class="col text-center d-lg-flex flex-column justify-content-lg-center align-items-lg-center">
            <h1 style="margin-bottom: 20px;">Règles du jeu</h1>
            <p>Retrouvez d'où ont été prises les photos !<br>Pour cela utilisez la carte qui apparaîtra sur votre téléphone et cliquez à l'endroit de votre supposition. Envoyez votre réponse en cliquant sur le bouton Répondre.<br>Plus vous êtes précis et rapide, plus vous marquez de points.</p>
          </div>
        </div>
      </div> -->

      <div class="d-flex flex-column" style="height: 100%;">
        <div class="row" style="height: 100%;width:100%">
          <div class="d-flex flex-column ml-4" >

            <div class="row flex-grow-1 mb-1" style="margin: 0px;">
              <div class="col d-lg-flex flex-column">

                <div class="mt-4 pb-2 d-flex d-md-flex align-items-center justify-content-between"> 

                  <div class="">
                    <canvas ref="qrcode_canvas"></canvas>
                  </div>

                  <div class="col-md-6 col-lg-8 d-md-flex justify-content-md-center align-items-md-center">
                    <div class="text-center text-dark bg-light border rounded border-light text-container" style="width: 100%;">
                      <p class="base-text">Rendez-vous sur le site www.sunomad.fr</p>
                      <p class="base-text">Cliquez sur le jeu enquête d'images</p> 
                      <p class="base-text">et rentrez le code suivant:</p>
                      <p class="code-partie"> {{ roomID_display }} </p>
                    </div>
                  </div>
                  
                </div>
                
                <div class="border rounded border-primary d-lg-flex flex-column justify-content-lg-center align-items-lg-center content-container">
                  <p>{{quizzData.name}}</p>
                  <h2>{{quizzData.description}}</h2>
                </div>
                
                <div class="grid-container" style="margin: 0px;height:50%">
                  <div class="title-jouers">
                    <h2>Joueurs ({{playerCount}}/{{maxPlayers}})</h2>
                  </div>
                  <div class="flex-container">
                    <div v-for="player in playerList" :key="player.ID" class="player-blob">
                      <div class="player-blob-content">{{player.pseudo}} <img :src="player.pseudoLogoUrl"></div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            
            <div class="mt-auto mb-2">
                <button @click="startGame" class="btn btn-primary" type="button" style="width: 100%;">Lancer la partie</button>
            </div>
          </div>
          <div class="col w-25 d-md-flex justify-content-md-center align-items-md-center">
            <img class="lobby-image" :src="lobbyPictureURL">
          </div>
        </div>
      </div>

    </div>

    <div v-if="step == 'starting'" class="container d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
      <h1 class="text-center">Lancement</h1><span class="spinner-grow textprimary text-primary" role="status"></span>
    </div>

    <div class="" v-if="step=='question'" style="height: 100%;">

      <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
        <div style="width: 100%;padding: 10px;">

          <div class="progress" style="width: 100%;margin-bottom: 20px;height: 2.2rem;">
            <div class="progress-bar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="progressBarStyle">
              <span class="visually-hidden fs-900 black-text">
              <span class="fs-900 black-text">{{ progress.points.toString().split('.')[0] }}</span>
              <span class="fs-400 black-text">,{{ progress.points.toFixed(1).split('.')[1]}}</span>
              points à gagner</span>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center" style="position: relative;height: 80vh;">
            <div class="d-lg-flex flex-column justify-content-lg-start align-items-lg-end" style="position: absolute;top: 10px;right: 15px;overflow: hidden;height: calc(100% - 10px);">
              <div v-for="ans in answers" :key="ans.pseudo" class="bg-dark border rounded" style="margin-bottom: 5px;">
                  <p style="margin: 5px 15px;">{{ans.time}}s {{ans.pseudo}}</p>
              </div>
            </div>
            
            
            <div v-if="currentQuestion.url" class="streets-container">
              <iframe :src="currentQuestion.url" width="600" height="450" allowfullscreen=""
                      loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div v-else class="d-flex justify-content-center align-items-center" style="position: relative;height: 80vh;">
              <img class="border rounded border-primary" :src="pictureURL" style="height: 100%;" />
            </div>
          
          </div>

        </div>
        <h4 class="text-center" style="position: fixed;top: 50%;right: 5%;">{{questionIndex + 1}}/{{questionsNb}}</h4>
      </div>

    </div>

    <div v-if="step == 'endQuestion'" class="container d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
      <h1 class="text-center">Chargement des résultats</h1><span class="spinner-grow textprimary text-primary" role="status"></span>
    </div>

    <div v-if="step=='resultsQuestion'" style="height: 100%;">
      <!-- <div v-if="vainqueurQuestion ">
        {{vainqueurQuestion}} a gagné cette question avec {{vainqueurQuestion_points}} points !
      </div>
      <div v-if="topPlayer">
        {{topPlayer}} est en tête de la partie avec {{topPlayer_points}} points !
      </div>   
      <hr>
      <ResultsMap :google="google" :mapSettings="quizzData.mapSettings" :questionCoords="currentQuestion.coords" :coords="coordsQuestion"/>  
     -->

      <div class="d-flex flex-column justify-content-lg-center align-items-lg-center" style="height: 100%;">
        <div style="width: 100%;padding: 10px;">

          <!-- <h1 class="text-center" :style="imageTitleStyle">{{currentQuestion.name}}</h1> -->
          <div class="d-flex justify-content-center align-items-center" style="margin-bottom: 20px;">
            <div class="d-flex justify-content-center align-items-center" style="position: relative;height: 70vh; width: 70vw;">
              <ResultsMap
                :google="google"
                :mapSettings="quizzData.mapSettings"
                :targetSettings="quizzData.targetSettings"
                :questionCoords="currentQuestion.coords"
                :coords="coordsQuestion"
                :coords_byDist="coords_byDist"
                :coordsOrderDist="coordsOrderDist"
                @results-done="resultsMapDone"
                style="height: 100%;"/>  
            </div>
          </div>

          <div class="row" style="margin: 0px; padding-top: 50px;">
            <div class="col" v-if="vainqueurQuestion">
              <div class="bg-success border rounded border-success" style="padding: 5px 10px;border-width: 3px!important;border-color:#030303!important">
                <p class="text-center text-dark" style="margin: 0px;">🏅 {{vainqueurQuestion.pseudo}} <img :src="vainqueurQuestion.pseudoLogoUrl"> <span class="badge bg-dark text-light">+ {{vainqueurQuestion.points}} points</span></p>
              </div>
            </div>
            <div class="col" v-if="topPlayer">
              <div class="bg-warning border rounded border-warning" style="padding: 5px 10px;border-width: 3px!important;border-color:#030303!important">
                <p class="text-center text-dark" style="margin: 0px;">🥇 {{topPlayer.pseudo}} <img :src="topPlayer.pseudoLogoUrl"> <span class="badge bg-danger text-light">{{topPlayer.points}} points</span></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    
    
    </div>

    <div v-if="step=='resultsQuestion_classement'" style="height: 100%;">

      <div class="d-flex flex-column justify-content-lg-center align-items-lg-center" style="height: 100%;">
        <div class="container" style="overflow: hidden;width: 100%;padding: 10px;max-height: 100vh;">

          <h1 class="text-center" style="margin-bottom: 50px;margin-top: 50px;">Classement</h1>

          <div v-for="(player, index) in results" :key="index" class="row" style="margin-bottom: 10px;">
            <div class="col">
              <div class="border rounded border-primary">
                <div class="row">
                  <div class="col-sm-2">
                    <p style="margin: 5px 10px;"><strong>#{{index + 1}}</strong></p>
                  </div>
                  <div class="col">
                    <p style="margin: 5px 10px;">{{player.pseudo}} <img :src="player.pseudoLogoUrl"> <span class="badge bg-primary black-text">{{player.points}} points</span></p>
                  </div>
                  <div class="col-sm-2">
                    <p v-if="player.classementMove > 0" class="text-success" style="margin: 5px 10px;text-align: right;">
                      <i  class="fa fa-chevron-up"></i>
                    </p>
                    <p v-if="player.classementMove < 0" class="text-danger" style="margin: 5px 10px;text-align: right;">
                      <i class="fa fa-chevron-down"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div v-if="step=='endGame'" style="height: 100%;">

      <!-- <div class="d-flex flex-column justify-content-lg-center align-items-lg-center" style="height: 100%;"> -->
      <div class="d-flex flex-column align-items-lg-center" style="height: 100%;">
        <div class="container" style="overflow: hidden;width: 100%;padding: 10px;max-height: 100vh; max-width: 1600px;">

          <h1 class="text-center" style="margin-bottom: 50px;margin-top: 50px;">Fin de la Partie</h1>

          <div  class="row" style="margin-bottom: 50px;height: 200px;">
            <div v-if="results[1]" class="col d-sm-flex align-items-sm-end">
              <div class="bg-secondary border rounded border-secondary" style="height: 80%;width: 100%;">
                <h1 class="text-center black-text" style="margin: 0px;">🥈 {{results[1].pseudo}} <img :src="results[1].pseudoLogoUrl"></h1>
                <h3 class="text-center black-text" style="margin: 0px;">{{results[1].points}} points</h3>
              </div>
            </div>
            <div v-if="results[0]" class="col">
              <div class="bg-warning border rounded border-warning" style="padding: 10px;height: 100%;">
                <h1 class="text-center black-text" style="margin: 0px;">🥇 {{results[0].pseudo}} <img :src="results[0].pseudoLogoUrl"></h1>
                <h3 class="text-center black-text" style="margin: 0px;">{{results[0].points}} points</h3>
              </div>
            </div>
            <div v-if="results[2]" class="col d-sm-flex align-items-sm-end">
              <div style="border-radius: 1rem;border-color: rgb(202,80,16);background: rgb(202,80,16);width: 100%;height: 100px;">
                <h1 class="text-center black-text" style="margin: 0px;">🥉 {{results[2].pseudo}} <img :src="results[2].pseudoLogoUrl"></h1>
                 <h3 class="text-center black-text" style="margin: 0px;">{{results[2].points}} points</h3>
              </div>
            </div>
          </div>

          <div v-for="(res, index) in results_withoutPodium" :key="index" class="row" style="margin-bottom: 10px;">
            <div class="col">
              <div class="border rounded border-primary">
                <p style="margin: 5px 10px;"><strong>#{{index + 4}}</strong> {{res.pseudo}} <img :src="res.pseudoLogoUrl"> <span class="badge bg-primary black-text">{{res.points}} points</span></p>
              </div>
            </div>
          </div>

          <div class="row" style="margin-bottom: 10px;">
            <div class="col text-center">
              <a class="btn btn-outline-light" type="button" href="../../pres">Nouvelle partie</a>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>

  .debug{
    outline: solid red;
  }

  .streets-container{
    overflow: hidden;
    position: relative;
    width: 80%;
    height: 0;
    margin-top: 56%;
    padding-bottom: 56%;
    top: -48%;
  }
  .streets-container > iframe{
    border: 0;
    position: absolute;
    width: 100%;
    height: 80%;
    left: 0;
    top: 0;
    
    left: -33px;
    /* top: -1.3vh; */
  }

  black-text{
    color: black;
  }

  .flex-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    flex-basis: 25%;
    
  }

  .title-jouers{
    margin-top: 1rem;
    max-height: 2rem;
  }

  .grid-container{
    display: grid;
    justify-content: center;
  }
  .left-container{
    max-width: 68%;
    padding-left: 10rem;
  }

  .player-blob{
   /* border rounded border-secondary */
   border-radius: 2rem;
   outline: solid rgb(151, 147, 147);
   max-height: 2rem;
   padding-left: 1rem;
   padding-right: 1rem;
  }
  .player-blob-content{
    padding-top: 0em;
    padding-left: 0em;
    /* max-width: 12em; */
  }


  .lobby-image{
    max-width: 70vw;
    min-width: 55vw;
    max-height: 100vh;
  }

  .base-text{
    font-size: 1rem;
    font-weight: 600;
  }

  .code-partie{
    font-size: 1.5rem;
    font-weight: 700;
  }

  .text-container{
    min-width: 20rem;
    max-width: 25rem;
    padding-bottom: .5rem;
    padding-top: .5rem;

  }
  .content-container{
    max-width: 35rem;
    align-content: center;
    padding: 1rem;
    text-align: center;
  }

  .content-container > h2 {
    font-size: 2rem;
  }

  .text-container > p {
    margin-bottom: .05rem;
  }

@media (min-width: 768px) { 
  .lobby-image{
    min-width: 40vw;
  }

 }



@media screen and (max-width: 576px){
  .text-container{
    min-width: 0px;
  }
  .base-text{
    font-size:.8rem;
  }

  .lobby-image{
    min-width: 30vw;
  }
  .content-container > h2 {
    font-size: 1.5rem;
  }

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .streets-container ::v-deep iframe  { 
    zoom: 5;  
  }
 
}  
</style>

<script>
const QRCode = require('qrcode')

import { nextTick } from 'vue'

// import Player from '@/components/admin/game/lobby/Player.vue'
import ResultsMap from '@/components/admin/game/ResultsMap.vue'
export default {
  name: 'PresGame',
  components: {
    // Player,
    ResultsMap,
  },
  data () {
    return {
      step: 'loading',

      quizzData: null,
      maxPlayers: 50,
      roomID: null,
      playerList: [],
      
      questionIndex: 0,
      questionsNb: 0,

      currentQuestion: null,
      pictureURL: null,
      progress: {
        value: 100,
        points: null,
      },
      progressHandlerTimeout: null,
      progressHandlerStopper: [],

      answers: [],

      topPlayer: null,
      // topPlayer_points: null,

      vainqueurQuestion: null,
      // vainqueurQuestion_points: null,
      // vainqueurQuestion_time: null,
      coordsQuestion: null,
      coords_byDist: null,
      coordsOrderDist: null,

      //imageTitleBlur: 25,

      results: [],
      results_withoutPodium: [],
    }
  },
  props: {
    quizz_unique_name: String,
    google: Object
  },
  mounted() {
    if (this.$route.params.maxPlayers) this.maxPlayers = this.$route.params.maxPlayers;
    this.createRoom();
  },
  computed: {
    playerCount() {
      return this.playerList.length
    },
    roomID_display() {
      // const half = Math.ceil(this.roomID.length / 2);    

      // const firstHalf = this.roomID.splice(0, half)
      // const secondHalf = this.roomID.splice(-half)

      const half1 = this.roomID.slice(0, this.roomID.length/2);
      const half2 = this.roomID.slice(this.roomID.length/2);

      return `${half1} ${half2}`
    },
    progressBarStyle() {
      if (this.progress.value) {
        return `width: ${this.progress.value}%;`;
      } else {
        return 'width: 0%;'
      }
      
    },
    // imageTitleStyle() {
    //   return `filter: blur(${this.imageTitleBlur}px);margin-bottom: 20px; transition: 1s filter linear;`;
    // },
    lobbyPictureURL() {
      return `${this.$store.state.serverIp}/api/lobbypic/get/lobby_picture.jpg?r=${this.pictureURL_append}`;
    },
  },
  sockets: {
    connect: function () {
      console.log('socket connected')
    },
    pres_newRoom_callback: function(data) {
      console.log(data);
      this.step = 'lobby';
      this.roomID = data.roomID;
      this.quizzData = data.quizzData;
      const roomUrl = `${this.$store.state.clientIp}/play/${this.roomID}`;

      var self = this
      nextTick(function() {
        QRCode.toCanvas(
          self.$refs.qrcode_canvas,
          roomUrl,
          { 
            errorCorrectionLevel: 'H',
            width: 100,
            margin: 1,
          },
          function (error) {
            if (error) console.error(error)
            console.log('success!');
          }
        );
      })

      
    },


    /**
     * LOBBY
     */
    pres_newPlayer: function(data) {
      console.log(`New player joined : ${data.pseudo}`);
      // console.log(data.playerList);
      this.update_PLayerList(data);
    },
    pres_playerLeft: function(data) {
      console.log(`Player left : ${data.pseudo}`);
      // console.log(data.playerList);
      this.update_PLayerList(data);
    },
    pres_kickPlayer_callback: function(data) {
      console.log(`Player kicked : ${data.pseudo}`);
      // console.log(data.playerList);
      this.update_PLayerList(data);
    },


    /**
     * GAME LOOP
     */
    pres_startGame_callback: function() {
      console.log(`room starting, wait 5 sec`);
      this.step = 'starting';
    },
    pres_newQuestion: function(data) {
      console.log(`Nouvelle question !`);
      // console.log(data)
      this.step = 'question';
      this.currentQuestion = data.question;
      this.questionIndex = data.questionIndex;
      this.questionsNb = data.questionsNb;
      this.pictureURL = `${this.$store.state.serverIp}/api/quizz/images/src/${this.quizzData.unique_name}/${this.currentQuestion.filename}`;

      this.answers = [];

      // Récuperer le url source de la tag html
      if (this.currentQuestion.url){
        let auxURL = this.currentQuestion.url.split('src="')[1].split('"')[0];
        this.currentQuestion.url = auxURL;
      }
      // if(this.progressHandlerTimeout) clearTimeout(this.progressHandlerTimeout);
      this.progress = {
              value: 100,
              points: 5.0 
      }
      this.progressHandlerStopper.push(false);
      let indexCurrentQuestion = this.progressHandlerStopper.length-1
      console.log(indexCurrentQuestion);
      if (indexCurrentQuestion > 0){
        this.progressHandlerStopper[indexCurrentQuestion-1] = true;
        console.log("PROGRESS STOPPED")
      }
      this.myProgressHandler(indexCurrentQuestion);
      // this.progressHandler(data.time*1000, data.time*1000)
    },
    pres_playerAnswered: function(data) {
      console.log(`${data.pseudo} a répondu en ${(data.time/1000).toFixed(2)}s`)

      this.answers.push({
        pseudo: data.pseudo,
        time: (data.time/1000).toFixed(2)
      })
    },
    pres_endQuestion: function() {
      console.log(`La question est finie !`);
      this.step = 'endQuestion';
    },
    pres_results: function(data) {
      console.log(`Resultats de la question`);
      this.resultsQuestion(data);
    },
    pres_endGame: function(results) {
      console.log(`La partie est finie !`);
      this.endGame(results)
    },
  },
  methods: {
    createRoom() {
      this.$socket.emit('pres_newRoom', {
        token: this.$store.state.login_token,

        quizz_unique_name: this.quizz_unique_name,
        maxPlayers: this.maxPlayers,
      });
    },
    kickPlayer(playerID) {
      this.$socket.emit('pres_kickPlayer', {
        token: this.$store.state.login_token, 

        playerID: playerID,
      });
    },
    update_PLayerList(data) {
      this.playerList = data.playerList.reverse();
      for (let i = 0; i < this.playerList.length; i++) {
        this.playerList[i].pseudoLogoUrl = `${this.$store.state.serverIp}/api/markers/${this.playerList[i].pseudo.replace(/\s+/g, '')}`
      }
    },
    startGame(e) {
      e.preventDefault();
      this.$socket.emit('pres_startGame', {
        token: this.$store.state.login_token, 
      });
    },
    resultsQuestion(data) {
      // console.log(data);

      if (data.vainqueurQuestion) {
        this.vainqueurQuestion = {
          pseudo: data.vainqueurQuestion ,
          points: data.vainqueurQuestion_points.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0],
          time: data.vainqueurQuestion_time.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0],
          pseudoLogoUrl: `${this.$store.state.serverIp}/api/markers/${data.vainqueurQuestion.replace(/\s+/g, '')}`
        }
      } else {
        this.vainqueurQuestion = null;
      }
      
      // this.vainqueurQuestion = data.vainqueurQuestion;
      // this.vainqueurQuestion_points = data.vainqueurQuestion_points.toFixed(2);
      // this.vainqueurQuestion_time = data.vainqueurQuestion_time.toFixed(2);
      this.coordsQuestion = data.coords;
      this.coords_byDist = data.coords_byDist;
      this.coordsOrderDist = data.coordsOrderDist;
      this.results = data.results;

      if (data.topPlayer) {
        this.topPlayer = {
          pseudo: data.topPlayer ,
          points: data.topPlayer_points.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0],
          pseudoLogoUrl: `${this.$store.state.serverIp}/api/markers/${data.topPlayer.replace(/\s+/g, '')}`
        }
      } else {
        this.topPlayer = null;
      }

      this.step = 'resultsQuestion';

      // var self = this;
      // console.log(data.coords.length);

      // setTimeout(function () {
      //   self.resultsQuestion_classement();
      // // }, 5000 + 5000 + 1000 + 500*data.coords.length + 5000);
      // }, 5000 + 2000 + 2500*data.coords_byDist.length + 500*(data.coords.length) + 5000);


      // this.imageTitleBlur = 25;

      // setTimeout(function () {
      //   self.imageTitleBlur = 0;
      // }, 5000);
      
    },
    resultsMapDone() {
      document.body.style.backgroundColor = "var(--dark)";
      this.resultsQuestion_classement();
    },
    resultsQuestion_classement() {
      var results = this.results;
      for (let i = 0; i < results.length; i++) {
        results[i].pseudoLogoUrl = `${this.$store.state.serverIp}/api/markers/${results[i].pseudo.replace(/\s+/g, '')}`
      }

      this.step = 'resultsQuestion_classement';

      var self = this;
      setTimeout(function () {
        self.nextQuestion();
      }, 5000);
    },
    nextQuestion() {
      this.$socket.emit('pres_nextQuestion', {
        token: this.$store.state.login_token, 
      });
    },

    endGame(results) {
      this.results = results;
      for (let i = 0; i < results.length; i++) {
        results[i].pseudoLogoUrl = `${this.$store.state.serverIp}/api/markers/${results[i].pseudo.replace(/\s+/g, '')}`
      }
      this.results_withoutPodium = results.slice(3);
      this.step = 'endGame';
    },

    async myProgressHandler(indexCurrentQuestion){
      /* We consider that the list pointsSettings.temps is of the form
         {max: 60, points:2}, {max:30, points:1}
         The points are in a sequence of 1, and max is the max seconds to get that score
      */
      
      let time = 60 * 1000
      let size = this.quizzData.pointsSettings.temps.length;
      let passedTime = 0;
      let timeDuringStep;
      let currentPoint = this.quizzData.pointsSettings.temps[size-1].points
      let realTimePassed = 0
      this.progress.points = currentPoint;
      function timeout(ms){
        return new Promise(resolve => setTimeout(resolve, ms))
      }

      for (let i = size -1; i >= 1; i--){
        /* Since we want a 10 decimals transition before the next value,
        we need to decide how many ms a decimal will last
        ex: 5.0, 4.9, 4.8, ..... 4.1*/
        timeDuringStep = this.quizzData.pointsSettings.temps[i].max - passedTime;
        timeDuringStep /= 10

        // A delay for each 0.1 decremented
        for (let j = 0; j < 10; j++){
          if (this.progressHandlerStopper[indexCurrentQuestion]){
            return;
          }
          await timeout(timeDuringStep*1000);
          if (this.progressHandlerStopper[indexCurrentQuestion]){
            return;
          }
          // Check if it wouldnt go to zero
          realTimePassed = (time - realTimePassed > 0) ? realTimePassed + timeDuringStep : realTimePassed;
          currentPoint -= 0.1;
          currentPoint = parseFloat(currentPoint.toFixed(1))
          this.progress = {
            value: (time-(realTimePassed*1000))*100/time,
            points: currentPoint
          }

        }
        passedTime = this.quizzData.pointsSettings.temps[i].max;
      }
    },
    
    progressHandler(currentTime, maxTime) {
      const timeInterval = 25;

      const newTime = currentTime - timeInterval

      var points = this.quizzData.pointsSettings.temps[0].points;

      for (let i = 1; i < this.quizzData.pointsSettings.temps.length; i++) {
        const points_temps = this.quizzData.pointsSettings.temps[i];

        if ((maxTime - newTime)/1000 <= points_temps.max) {
          points = points_temps.points
        }
      }

      var self = this;
      this.progressHandlerTimeout = setTimeout(() => {
        self.progress = {
          value: newTime*100/maxTime,
          // points: this.quizzData.pointsSettings.distance[ this.quizzData.pointsSettings.distance.length - 1 ].points + points,
          points: points,
        }
        

        if (newTime > 0) {
          self.progressHandler(newTime, maxTime);
        }        
      }, timeInterval);

    },
  }

}
</script>