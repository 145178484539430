<template>

  <div class="container" style="height: 100%;">

    <div class="d-flex flex-column justify-content-center align-items-center" style="height: 95%;">
      <h1 class="text-center">Enquête d'images</h1>
      <hr>
      <JoinRoom/>
    </div>

    <footer>
      <p class="text-end"><a href='/pres'><em>Administration</em></a></p>
    </footer>
    </div>
</template>

<script>
import JoinRoom from '@/components/game/JoinRoom.vue'

export default {
  name: 'Home',
  components: {
    JoinRoom
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
