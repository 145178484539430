<template>
  <div class="row" style="margin-bottom: 20px;padding: 10px;border-radius: 1em;border-width: 1px;border-style: solid;margin-right: 0px;margin-left: 0px;">
    <div class="col-md-2 d-sm-flex flex-column justify-content-sm-center align-items-sm-start">
      <div v-if="hasURL">
        <div class="strets-container" v-html="quizzData.questions[0].url"></div>
      </div>
      <div v-else>
        <picture><img id="preview" :src="previewURL" width="100"/></picture>
      </div>
    </div>
    <div class="col-sm-4 col-md-6 col-xl-7 d-sm-flex flex-column justify-content-sm-center align-items-sm-start">
      <h1>{{quizzData.name}}</h1>
      <p>{{quizzData.description}}</p>
    </div>
    <form @submit="startGame" class="col-sm-3 col-xl-2 d-sm-flex flex-column justify-content-sm-center">
      <!-- <form @submit="startGame"> -->
        <div class="input-group">
          <input class="form-control" style="margin-bottom: 5px;" type="number" placeholder="Joueurs max" name="maxPlayers" v-model="maxPlayers" required>
        </div>
        <button class="btn btn-primary" type="submit">Lancer</button>
      <!-- </form> -->
    </form>
    <div class="col-sm-2 col-md-1 d-sm-flex justify-content-sm-end align-items-sm-center">
      <button class="btn btn-outline-danger" @click="deleteQuizz" v-if="!loading"><i class="fa fa-trash"></i></button>
      <button class="btn btn-outline-danger" v-if="loading" disabled>...</button>
    </div>
  </div>
</template>

<style scoped>
.strets-container ::v-deep iframe{
    border:0;
    position: absolute;
    bottom:0; 
    right:0; 
    width:100%; 
    height:100%;
  }
</style>

<script>
export default {
  name: 'SingleQuizz',
  components: {
    
  },
  data () {
    return {
      loading: false,
      maxPlayers: 50,
    }
  },
  props: {
    quizzData: Object,
  },
  methods: {
    deleteQuizz(e) {
      e.preventDefault();

      const axios = require('axios');

      var self = this;
      axios({
        method: "get",
        url: this.$store.state.serverIp + '/api/pres/quizz/delete/' + this.quizzData.unique_name,
        headers: {
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },
      }).then(function() {

        self.loading = false;
        self.$emit('deleted');
        
      }).catch(function(thrown) {

        console.log(thrown); 
        self.loading = false;

      });
    },

    startGame(e) {
      e.preventDefault();      
      this.$router.push(`/pres/${this.quizzData.unique_name}/${this.maxPlayers}`);
    }
  },
  mounted: function() {
    // console.log(this.quizzData.questions);
  },
  computed: {
    hasURL(){
      if (this.quizzData.questions[0]){
        if(this.quizzData.questions[0].url){
          return true;
        }
      }
      return false;
    },
    previewURL() {
      if (this.quizzData.questions.length > 0) {
        return this.$store.state.serverIp + '/api/quizz/images/src/' + this.quizzData.unique_name + '/' + this.quizzData.questions[0].filename;
      } else {
        return '';
      }
    }
  },

}
</script>