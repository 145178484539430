<template>
  <div style="height: 1000px; width: 100%; margin-bottom: 20px;">

      <div v-if="zonePoints == null"><h1 class="text-center">Résultats</h1></div>
      <div class="bg-primary" v-if="zonePoints != null"><h1 class="bg-primary text-center black-color">Zone à {{zonePoints}} point(s)</h1></div>

      <div class="border rounded border-primary" :style="mapRef_style">
        <div ref="mapRef" class="rounded" style="height: 100%; widht: 100%;"></div>
      </div>

  </div>
  
</template>
<style scoped>
.black-color{
  color: black;
}
</style>
<script>
// import { nextTick } from 'vue'
const haversine = require('haversine-distance');

export default {
  name: 'ResultsMap',
  components: {
  },
  data () {
    return {
      map: null,

      targetSettings_adv: [],
      targetSettings_byDist: [],

      zonePoints: null,

      mapRef_style_boxShadow: '',

      answerDist_index: 0,
      answerPlayer_index: 0,
      answerCount: 0,

      targetShowed: false,
    }
  },
  props: {
    google: Object,
    mapSettings: Object,
    targetSettings: Array,

    questionCoords: Object,
    coords: Array,
    coords_byDist: Array,
    coordsOrderDist: Array,
    
  },
  computed : {
    mapRef_style() {
      return `height: 100%; widht: 100%; ${this.mapRef_style_boxShadow}`;
    }
  },
  async mounted() {

    this.initTargetSettings();
    this.initMap();

    var self = this;

    // déZoom et cible
    setTimeout(() => {
       self.showRedCircles();
    }, 5000);


  },
  methods: {

    timeout(ms){
        return new Promise(resolve => setTimeout(resolve, ms))
    },

    distToZoom(dist) { // en mêtres
      return -1.178*Math.log(dist) + 24.242 - 1

    },

    getZoomLevel(circle) {
        let zoomLevel=0;
        if (circle != null){
            let radius = circle.getRadius();
            let scale = radius / 500;
            zoomLevel = (16 - Math.log(scale) / Math.log(2));
        }
        return zoomLevel;
    },

    getZoomLevelFromRadius(radius){
      let scale = radius / 500;
      return (16 - Math.log(scale) / Math.log(2));
    },

    setMapRef_style_boxShadow(position) {
      let color = "var(--dark)";
      if (position == 1) color = "#ffc266";
      else if (position == 2) color = "var(--secondary)";
      else if (position == 3) color = "#cd7f32";
      else color = "var(--dark)";

      document.body.style.backgroundColor = color;
    },

    initTargetSettings() {
      for (let i = 0; i < this.targetSettings.length; i++) {
        const circleSettings = this.targetSettings[i];

        // used to show target
        this.targetSettings_adv.push({
          max : circleSettings.max,
          points : circleSettings.points,
          color : circleSettings.color,
        })

        // used to show player marker by distance
        this.targetSettings_byDist[circleSettings.points] = {
          max : circleSettings.max,
          points : circleSettings.points,
          color : circleSettings.color,
        }
      }
    },

    initMap() {
      const google = this.google;
      const map = new google.maps.Map(this.$refs.mapRef, {
        // mapId: "149b6144af68fd13",
        // mapTypeId: "satellite",
        mapTypeId: "hybrid",
        tilt: 0,

        center: this.mapSettings.center,
        zoom: this.mapSettings.zoom,

        disableDefaultUI: true,
      });
      map.setOptions({
        styles: [
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility:"off"}]
          }, {
            featureType: "poi.park",
            elementType: "all",
            stylers: [{ visibility:"on"}]
          }, {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility:"off"}]
          }
        ]
      });
      this.map = map;
    },

    // Depending on the bounds of the quizz's settings
    getHeightArea(){
      
      let upperEast = { "lat": this.mapSettings.bounds.north, "lon": this.mapSettings.bounds.east};
      let downWest  = { "lat": this.mapSettings.bounds.south, "lon": this.mapSettings.bounds.east};
      let height = haversine(upperEast, downWest);
       
      return height;
    },

    async showRedCircles(){

      const google = this.google;
      const map = this.map;
      // let alpha = this.getScalingFactor()
      map.setCenter(this.questionCoords);
      let orderedDists = [...this.coordsOrderDist];
      let nbResponses = orderedDists.length;
      let answersAlreadyDisplayed = 0;
      let heightArea = this.getHeightArea();
      let circles = [];

      console.log("HeightArea::", heightArea);
      if(heightArea >= 2000000){
        circles.push(1200 * 1000);
      }
      if(heightArea >= 1200000){
        circles.push(340 * 1000);
      }
      if(heightArea >= 340000){
        circles.push(55 * 1000);
      }
      if(heightArea >= 10000){
        circles.push(8 * 1000);
      }
      if(heightArea >= 1600){
        circles.push(1.5 * 1000);
      }

      console.log("CIRCLES:::", circles);
      let circle;
      let j = 0;

      // We add the first couronne of the "cible" (target) at the end of the circles list
      // because we need to display all players that are outside of the target. We need it as a delimiter
      circles.push(this.targetSettings_adv[1].max);

      for (let i = 0; i < circles.length-1; i++){
        
        // For the first couronne, show the players outside of it
        if (i == 0){
            // Run through the list of players's answers untill we find one that is smaller than circle.
            while (orderedDists.length && (circles[i] < orderedDists[j].distance)){
              //change the background for 3 best answers.
              console.log("NUMB:::", nbResponses - answersAlreadyDisplayed);
              if (nbResponses - answersAlreadyDisplayed <= 3){
                this.setMapRef_style_boxShadow(nbResponses - answersAlreadyDisplayed);
              }
              // Show "Zone à x points"
              this.zonePoints = this.getBestZone(orderedDists[j].distance);
              this.showPlayerMarker(orderedDists[j], 0 /*wait time*/);
              //remove the scores of that player, since he has already been displayed
              orderedDists.shift();
              answersAlreadyDisplayed++;
              await this.timeout(2 * 1000);
            }
        }


        circle = new google.maps.Circle({
            strokeColor: "#ff0303",
            strokeOpacity: 1,
            strokeWeight: 4,
            fillColor: '#ff0303',
            fillOpacity: 0.0,
            map,
            center: this.questionCoords,
            radius: circles[i],
          });


        await this.timeout(2 * 1000);
        //Zoom in the circle
        map.setZoom(this.getZoomLevel(circle));
        await this.timeout(1.5 * 1000);

        //Delete circle
        circle.setMap(null);
        //Show players that are inside this couronne but outside the next one
        while (orderedDists.length &&
         (circles[i] > orderedDists[j].distance) &&
         ((circles[i+1] < orderedDists[j].distance))){

          if (nbResponses - answersAlreadyDisplayed <= 3){
            this.setMapRef_style_boxShadow(nbResponses - answersAlreadyDisplayed);
          }
          // Show "Zone à x points"
          this.zonePoints = this.getBestZone(orderedDists[j].distance);
          this.showPlayerMarker(orderedDists[j], 0 /*wait time*/);
          answersAlreadyDisplayed++;
          //remove the scores of that player, since he has already been displayed
          orderedDists.shift();
        }

        await this.timeout(2 * 1000);
      }
      
      await this.timeout(2 * 1000);
      this.showCible();
      await this.timeout(0.5 * 1000);

      let circlesCible = [...this.targetSettings_adv]
      
      for (let i = 1; i < circlesCible.length; i++){
        map.setZoom(this.getZoomLevelFromRadius(circlesCible[i].max));
        await this.timeout(0.5 * 1000);
        //Show players that are inside this couronne but outside the next one
        while (orderedDists.length &&
        (circlesCible[i].max > orderedDists[j].distance)){
          
          if ( i != circlesCible.length - 1 &&
          (circlesCible[i+1].max > orderedDists[j].distance)){
            break;
          }

          //change the background for 3 best answers.
          if (nbResponses - answersAlreadyDisplayed <= 3){
            this.setMapRef_style_boxShadow(nbResponses - answersAlreadyDisplayed);
          }
          // Show "Zone à x points"
          this.zonePoints = this.getBestZone(orderedDists[j].distance);
          this.showPlayerMarker(orderedDists[j], 0 /*wait time*/);
          answersAlreadyDisplayed++;
          //remove the scores of that player, since he has already been displayed
          orderedDists.shift();
          
          await this.timeout(2 * 1000);
        }
        await this.timeout(1 * 1000);
      }

      this.animateCible();
    },

    getBestZone(dist){
      // let bestDist = this.coordsOrderDist[this.coordsOrderDist.length-1];
      
      for (let i = this.targetSettings_adv.length-1; i > 0; i--){
        if (dist < this.targetSettings_adv[i].max){
          return this.targetSettings_adv[i].points
        }
      }
      return 0
    },

    async animateCible() {

      this.zonePoints = null;
      
      this.mapRef_style_boxShadow = 'box-shadow: 0px 0px 50px rgb(156, 39, 176);';
      await this.timeout(0.5 * 1000)
      this.$emit('results-done');

    },

    showGlobal() {
      const map = this.map;
      map.setCenter(this.questionCoords);
      map.setZoom(this.distToZoom((this.targetSettings[1].max)*10));
    },

    showCible() {
      console.log("showing target");

      this.targetShowed = true;

      const google = this.google;
      const map = this.map;

      // map.setCenter(this.questionCoords);

      for (let i = 1; i < this.targetSettings_adv.length; i++) {
        const circleSetting = this.targetSettings_adv[i];

        new google.maps.Circle({
          strokeColor: circleSetting.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: circleSetting.color,
          fillOpacity: 0.05,
          map,
          center: this.questionCoords,
          radius: circleSetting.max,
        });
      }
    },

    showPlayerMarker(playerAnswer, waitTime) {
      const google = this.google;
      const map = this.map;

      const marker = new google.maps.Marker({
        position: playerAnswer.coords,
        map,
        icon: {
          url: `${this.$store.state.serverIp}/api/markers/${playerAnswer.pseudo.replace(/\s+/g, '')}`
        },
        opacity: 1,
      });

      const self = this;
      setTimeout(() => {
        self.animMarkerOpacity(marker);         
      }, 500);
      return waitTime
      // setTimeout(() => {
      //   self.showResults();
      // }, waitTime);

    },

    animMarkerOpacity(marker) {
      for (var m = 0; m < 500; m++) {
        setTimeout(() => {
          marker.setOpacity(marker.getOpacity() - 1/500) 
        }, m*20);
      }
    },

  }
}
</script>
