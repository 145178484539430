import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-3-socket.io'

// import './assets/bootstrap/css/bootstrap.min.css';
// import './assets/bootstrap/js/bootstrap.min.js';

createApp(App)
.use(store)
.use(router)
.use(new VueSocketIO({
  // debug: true,
  connection: 'https://play.sunomad.fr',
  // connection: 'localhost:4000',
  // connection: '193.70.88.78:4000',
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
  // options: { path: "/my-app/" } //Optional options
}))
.mount('#app');

// import Vue from 'vue'
// import store from './store'
// import router from './router'
// import App from './App.vue'
// import VueSocketIO from 'vue-3-socket.io'

// Vue.use(new VueSocketIO({
//     debug: true,
//     connection: 'http://localhost:4000',
//     vuex: {
//         store,
//         actionPrefix: 'SOCKET_',
//         mutationPrefix: 'SOCKET_'
//     },
//     // options: { path: "/my-app/" } //Optional options
// }))

// new Vue({
//     router,
//     store,
//     render: h => h(App)
// }).$mount('#app')
