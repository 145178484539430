<template>
  <div>
    Auth

    <p v-if="error">{{ error }}</p>

    <form @submit="loginFormSubmit">
      <input type="text" placeholder="Identifiant" name="identifiant" v-model="identifiant" required>
      <input type="password" placeholder="Mot de passe" name="password" v-model="password" required>

      <button v-if="!loading" type="submit">Connexion</button>
      <button v-if="loading" type="submit" disabled>...</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Auth',
  data () {
    return {
      loading: false,
      error: null,

      identifiant: null,
      password: null
    }
  },
  methods: {
    loginFormSubmit(e) {
      e.preventDefault();

      this.loading = true;
      this.error = '';

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json" ,
          },
        body: JSON.stringify({
          identifiant: this.identifiant,
          password: this.password
        })
      };

      

      var self = this;

      fetch(this.$store.state.serverIp + '/api/auth/login', requestOptions)
        .then(async function (response) {

          self.loading = false;

          switch (response.status) {
            case 200:
              var body = await response.json();
              self.$emit('login', body.jwt)
              break;
            case 400:
              self.error = "Tout les champs sont obligatoires";
              break;
            case 403:
              self.error = "Identifiant / Mot de passe incorrect";
              break;
            default:
              self.error = "Une erreur est survenue au niveau du serveur";
              break;
          }
        });

    }
  }
}
</script>