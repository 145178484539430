<template>
  <div>

    <hr>

    <img :src="imageURL" width="250"/>
    {{ name }} | {{ filename }} | {{ coords }}
    <button @click="deleteQuestion">Supprimer</button>

    <hr>

  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    filename: String,
    name: String,
    coords: Object,
    quizz_unique_name: String,
  },
  computed: {
    imageURL() {
      return this.$store.state.serverIp + '/api/quizz/images/src/' + this.quizz_unique_name + '/' + this.filename;
    }
  },
  methods: {
    deleteQuestion(e) {
      e.preventDefault();

      const axios = require('axios');

      var self = this;
      axios({
        method: "get",
        url: `${this.$store.state.serverIp}/api/pres/quizz/question/delete/${this.quizz_unique_name}/${this.filename}`,
        headers: {
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },
      }).then(function() {

        self.loading = false;
        self.$emit('deleted');
        
      }).catch(function(thrown) {

        console.log(thrown); 
        self.loading = false;

      });

    }
  }
}
</script>