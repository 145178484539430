<template>
  <router-view/>
</template>

<script>
export default {
  created(){
        document.title = "Enquête d'images"
  }
}
</script>

<style lang="css">
  @import './assets/bootstrap/css/bootstrap.min.css';
  @import './assets/fonts/font-awesome.min.css';
  @import './assets/global.css';

  #app {
    height:100%
  }
</style>
