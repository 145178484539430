<template>
  <div style="height:100%;width: 100%;">

    <!-- <div style="position: absolute;top: 0px;z-index: 9;width: 100%;">
      <progress max="100" :value="progress.value"> {{progress.points}} </progress>
    </div> -->

    <div class="progress" style="position: absolute;top: 0px;width: 100%; z-index: 99;height:2.2rem;">
      <div class="progress-bar" :aria-valuenow="progress.value" aria-valuemin="0" aria-valuemax="100" :style="progressBarStyle">
        <span class="visually-hidden fs-900 black-text">
          <span class="fs-900 black-text">{{ progress.points.toString().split('.')[0] }}</span>
          <span class="fs-400 black-text">,{{ progress.points.toFixed(1).split('.')[1]}}</span>
          points à gagner
        </span>
      </div>
    </div>

    <div class="border rounded border-primary d-flex justify-content-end" style="position: absolute;top: 40px;right: 10px;background: var(--primary); z-index: 99;">
      <p style="margin: 5px;margin-right: 20px;margin-left: 20px;">{{pseudo}}</p>
    </div>

    <div style="width: 100%;height: 100%;">
      <!-- <iframe allowfullscreen="" frameborder="0" loading="lazy" src="https://www.google.com/maps/embed/v1/view?key=AIzaSyBmBMqdz2WB59Hzgpyw-GSSjXQqY31WIVA&amp;center=-33.8569%2C151.2152&amp;zoom=11" width="100%" height="400" style="height: 100%;width: 100%;"></iframe>
       -->
      <Map :google="google" :mapSettings="mapSettings" @answer="answer"></Map>
    </div>

    

  </div>
</template>

<script>
import Map from '@/components/game/Map.vue'

export default {
  name: 'Question',
  components: {
    Map
  },
  data () {
    return {
      loading: false,
      
      progress: {
        value: 100,
        points: null,
      },

      progressHandlerStopper: [],
    }
  },
  computed: {
    progressBarStyle() {
      if (this.progress.value) {
        return `width: ${this.progress.value}%;`;
      } else {
        return 'width: 0%;'
      }
      
    }
  },
  props: {
    google: Object,
    mapSettings: Object,
    time: Number,
    pseudo: String,
    pointsSettings: Object,
  },
  created: function() {
    // this.progressHandler(this.time, this.time);
    this.progress = {
            value: 100,
            points: 5.0 
    }
    this.progressHandlerStopper.push(false);
    let indexCurrentQuestion = this.progressHandlerStopper.length-1
    console.log(indexCurrentQuestion);
    if (indexCurrentQuestion > 0){
      this.progressHandlerStopper[indexCurrentQuestion-1] = true;
      console.log("PROGRESS STOPPED")
    }
    this.myProgressHandler(indexCurrentQuestion);
  },
  methods: {
    answer(coords) {
      this.loading = true;

      this.$emit('answer', coords, this.progress.points);
      
    },
    timeAnswer(points) {
      this.$emit('timeAnswer', points);
    },
    async myProgressHandler(indexCurrentQuestion){
      /* We consider that the list pointsSettings.temps is of the form
         {max: 60, points:2}, {max:30, points:1}
         The points are in a sequence of 1, and max is the max seconds to get that score
      */
      let size = this.pointsSettings.temps.length;
      let passedTime = 0;
      let timeDuringStep;
      let currentPoint = this.pointsSettings.temps[size-1].points
      let realTimePassed = 0
      function timeout(ms){
        return new Promise(resolve => setTimeout(resolve, ms))
      }
      this.progress.points = currentPoint;
      for (let i = size -1; i >= 1; i--){
        /* Since we want a 10 decimals transition before the next value,
        we need to decide how many ms a decimal will last
        ex: 5.0, 4.9, 4.8, ..... 4.1*/
        timeDuringStep = this.pointsSettings.temps[i].max - passedTime;
        timeDuringStep /= 10

        // A delay for each 0.1 decremented
        for (let j = 0; j < 10; j++){
          if (this.progressHandlerStopper[indexCurrentQuestion]){
            return;
          }
          await timeout(timeDuringStep*1000);
          if (this.progressHandlerStopper[indexCurrentQuestion]){
            return;
          }
          // Check if it wouldnt go to zero
          realTimePassed = (this.time - realTimePassed > 0) ? realTimePassed + timeDuringStep : realTimePassed;
          currentPoint -= 0.1;
          currentPoint = parseFloat(currentPoint.toFixed(1))
          this.progress = {
            value: (this.time-(realTimePassed*1000))*100/this.time,
            points: currentPoint
          }


        }
        passedTime = this.pointsSettings.temps[i].max;
      }

    },
    progressHandler(currentTime, maxTime) {
      const timeInterval = 25;

      const newTime = currentTime - timeInterval

      var points = this.pointsSettings.temps[0].points;
      
      for (let i = 1; i < this.pointsSettings.temps.length; i++) {
        const points_temps = this.pointsSettings.temps[i];

        if ((maxTime - newTime)/1000 <= points_temps.max) {
          points = points_temps.points
        }
      }

      var self = this;
      setTimeout(() => {
        self.progress = {
          value: newTime*100/maxTime,
          // points: this.pointsSettings.distance[ this.pointsSettings.distance.length - 1 ].points + points,
          points: points,
        }
        

        if (newTime > 0) {
          self.progressHandler(newTime, maxTime);
        }        
      }, timeInterval);

    },
  }

}
</script>