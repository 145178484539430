import { createStore } from 'vuex'

export default createStore({
  state: {
     serverIp: 'https://play.sunomad.fr',
     clientIp: 'https://play.sunomad.fr',
    // serverIp: 'http://localhost:4000',
    // clientIp: 'http://localhost:4001',
    // serverIp: 'http://193.70.88.78:4000',
    // clientIp: 'http://193.70.88.78:4001',
    login_token: null,
    pointsDistance: [6, 7, 8, 10],

    game: {
      roomID: null,
      // pseudo: null,
    }
  },
  mutations: {
    LOGIN_TOKEN(state, token) {
      state.login_token = token;
    },
    JOIN_ROOM(state, data) {
      state.game.roomID = data.roomID;
      // state.game.pseudo = data.pseudo;
    },
    updatePoints(state, points){
      state.pointsDistance = [...points];
    }

  },
  actions: {
  },
  modules: {
  },
})
