<template>
  <div class="flex-grow-1" style="overflow: auto;margin-bottom: 50px; ">
        
    <!-- QuizzList
    <p v-if="error">{{ error }}</p>
    <button @click="refresh">Rafraichir</button> -->
    
    <SingleQuizz v-for="quizz in quizzList"
      :key="quizz.unique_name"   
      :quizzData="quizz"
      @deleted="refresh"/>

  </div>
</template>

<script>
import SingleQuizz from '@/components/admin/SingleQuizz.vue'

export default {
  name: 'QuizzList',
  components: {
    SingleQuizz,
  },
  data () {
    return {
      loading: false,
      error: '',

      quizzList: []
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh(e = null) {
      if (e) e.preventDefault();

      this.loading = true;
      this.error = '';

      const axios = require('axios');

      var self = this;
      axios({
        method: "get",
        url: this.$store.state.serverIp + '/api/pres/quizz/list',
        headers: {
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },
      }).then(function(response) {

        self.quizzList = response.data;
        self.loading = false;
        
      }).catch(function(thrown) {

        console.log(thrown); 
        self.loading = false;

      })
    }
  }

}
</script>

<style>
  * {
    scrollbar-width: auto;
    scrollbar-color: #6c757d #011627;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #011627;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #6c757d;
    border-radius: 10px;
    border: 0px solid #ffffff;
  }
</style>