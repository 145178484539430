<template>
  <div>
    UpdateLobbyPicture

    <p v-if="error">{{ error }}</p>
    <button v-if="!loading" @click="close_btn">Fermer</button>
    <button v-if="loading" disabled>...</button>

    <hr>
    <img :src="pictureURL" width="250" />
    <hr>

    <form @submit="submit">

      <p>Images .jpg seulement</p>
      <input type="file" name="uploadFile" accept=".jpg,.jpeg" ref="update_fileinput" @change="update_fileinput" require>
      <img id="preview" :src="preview_url" width="250"/>

      <button v-if="!loading" type="submit">Mettre à jour</button>
      <button v-if="loading" type="submit" disabled>{{ progress }}</button>

    </form>
      

  </div>
</template>

<script>
export default {
  name: 'UpdateLobbyPicture',
  components: {

  },
  data () {
    return {
      step: "title",

      loading: false,
      error: '',

      pictureURL_append: '',

      file: null,
      preview_url: null,    

      progress: 0

    }
  },
  computed: {
    pictureURL() {
      return `${this.$store.state.serverIp}/api/lobbypic/get/lobby_picture.jpg?r=${this.pictureURL_append}`;
    },
  },
  methods: {
    update_fileinput(e) {
      e.preventDefault();

      this.file = e.target.files[0];   

      this.preview_url = URL.createObjectURL(this.file);
    },

    submit(e) {
      e.preventDefault();

      const axios = require('axios');

      var formdata = new FormData();
      formdata.append("uploadFile", this.file );

      var self = this;

      this.loading = true;

      this.uploadRequest = axios({

        method: "post",
        data: formdata,

        url: this.$store.state.serverIp + '/api/pres/lobbypic/set',
        headers: {
          'Content-Type': 'multipart/form-data' ,
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },

        onUploadProgress: function(event) {
          self.progress = Math.round((event.loaded/event.total)*100);
        },

      }).then(function(response) {

        console.log("updated");
        console.log(response);

        self.file = null;
        self.preview_url = null;
        self.progress = 0;

        self.$refs.update_fileinput.value = null;
        self.loading = false;

        self.pictureURL_append += '1';
        
      }).catch(function(thrown) {

        console.log("Erreur lors de l'envoi");
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          console.log(thrown); 
        }

        self.loading = false;
      })
    },

    close_btn(e) {
      e.preventDefault();
      this.$emit('close');
    },



  }
}
</script>