<template>
  <div style="height: 100%;">
    <div v-if="loading">
      ...
    </div>
    <div style="height: 100%;" v-if="!loading">
      <Auth v-if="!logged" @login="login" />
      <QuizzManager v-if="logged && !quizz_unique_name" :google="google"/>
      <PresGame v-if="logged && quizz_unique_name" :quizz_unique_name="quizz_unique_name" :google="google"/>
    </div>
  </div>
</template>

<script>
import {Loader} from 'google-maps';
// const loader = new Loader('AIzaSyBmBMqdz2WB59Hzgpyw-GSSjXQqY31WIVA');
const loader = new Loader('AIzaSyBPOI9ZRAErZFh129HNBt3JSZhMmyB0580');




import Auth from '@/components/admin/Auth.vue'
import QuizzManager from '@/components/admin/QuizzManager.vue'
import PresGame from '@/components/admin/PresGame.vue'


export default {
  name: 'Admin',
  components: {
    Auth,
    QuizzManager,
    PresGame
  },
  data () {
    return {
      logged: false,
      loading: true,
      google: null,
    }
  },
  computed: {
    quizz_unique_name() {
      if (this.$route.params.quizz_unique_name) {
        return this.$route.params.quizz_unique_name
      } else {
        return null
      }
    }
  },
  mounted: async function () {
    this.autoLog();

    const google = await loader.load();
    this.google = google;
  },
  methods: {
    autoLog() {

      var getCookie = function(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
          c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
          }
        }
        return "";
      }

      const authCookie = getCookie('auth');

      var self = this;

      if (authCookie) {


        const requestOptions = {
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + authCookie,
          }
        };
        fetch(this.$store.state.serverIp + '/api/auth/check', requestOptions)
          .then(function (response) {
            switch (response.status) {
              case 200:
                self.logged = true;

                self.$store.commit('LOGIN_TOKEN', authCookie)

                self.loading = false;
                break;
              default:
                document.cookie = "auth= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
                self.loading = false;
                break;
            }
          });
        
      } else {
        self.loading = false;
      }

    },

    login(jwt) {
      const setCookie = function(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      }

      setCookie('auth', jwt, 1);
      this.$store.commit('LOGIN_TOKEN', jwt)

      this.logged = true;
    }
  }
}
</script>