<template>
  <div style="width: 100%; height: 100%">

      <div ref="mapRef" style="width: 100%; height: 100%" ></div>
      <!-- <div style="position: absolute;bottom: 75px;">
        <button @click="answer" >Répondre</button>
        <button v-if="!latLng" disabled>Répondre</button>
      </div> -->

      <div class="d-flex justify-content-center" style="position: relative;bottom: 75px; z-index: 99;">
        <button v-if="latLng" @click="answer" class="btn btn-danger" type="button" style="padding-right: 100px;padding-left: 100px;">
          Répondre
        </button>
        <!-- <button v-if="!latLng" class="btn btn-danger" type="button" style="padding-right: 100px;padding-left: 100px;" disabled>
          Répondre
        </button> -->
      </div>
      

  </div>
  
</template>

<script>

var clickMarker;

export default {
  name: 'Map',
  components: {
  },
  data () {
    return {
      latLng: null,
      map: null,

      clickMarkerSET: false,

    }
  },
  props: {
    google: Object,
    mapSettings: Object,
  },
  async mounted() {
    const google = this.google;

    const map = new google.maps.Map(this.$refs.mapRef, {
      mapTypeId: "hybrid",
      tilt: 0,

      center: this.mapSettings.center,
      zoom: this.mapSettings.zoom,

      gestureHandling: "greedy",
      
      restriction: {
        latLngBounds: this.mapSettings.bounds
      },

      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    });

    map.setOptions({
      styles: [
              {
                featureType: "all",
                stylers: [{ visibility:"off" }]
              },{
                featureType: "road",
                elementType: "labels",
                stylers: [{ visibility:"on" }]
              },{
                featureType: "administrative",
                stylers:[{ visibility:"on" }]
              }

            ]
    });

    map.setClickableIcons(false);
 
    this.map = map;

    var self = this;
    map.addListener("click", function(e) {

      if (self.clickMarkerSET) {
        clickMarker.setMap(null);
      }
      
      clickMarker = new google.maps.Marker({
        position: e.latLng,
        map,
      });

      self.latLng = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
      self.clickMarkerSET = true;
    });


  },
  methods: {

    answer(e) {
      e.preventDefault();

      this.$emit('answer', this.latLng);
    }

  }
}
</script>