<template>
  <div style="height: 100%;">

    <div>
      <UpdateLobbyPicture v-if="show == 'updateLobbyPic'" @close="updateLobbyPicClose"/>
    </div>

    <div>
      <QuizzCreate v-if="show == 'create'" @close="createClose" :google="google"/>
    </div>

    <div>
      <PointsModify v-if="show == 'modifyPoints'" @close="modifyPointsClose"/>
    </div>
    

    <div class="container d-flex flex-column" style="height: 100%;">
      <div class="row" style="padding-top: 20px;padding-bottom: 20px;margin-bottom: 50px;">
        <div class="col d-sm-flex align-items-sm-center">
          <h1>Administration</h1> 
        </div>
        <div class="col-sm-4 col-md-3 col-lg-3 col-xl-3 d-sm-flex flex-column">
          <button class="btn btn-success" type="button" style="margin-bottom: 10px;" @click="showCreate">Nouveau</button>
          <button class="btn btn-warning" type="button" style="margin-bottom: 10px;" @click="showUpdateLobbyPic">Mettre à jour la photo du Lobby</button>
          <!-- <button class="btn btn-outline-danger" type="button" disabled>Déconnexion</button> -->
          <button v-if="!restart_loading"  class="btn btn-danger" type="button" style="margin-bottom: 10px;" @click="restart_serv">Redémarrer</button>
          <button class="btn btn-primary" type="button" style="margin-bottom: 10px;" @click="showModifyPoints">Modifier les points</button>
          <button v-if="restart_loading" class="btn btn-danger" type="button" @click="restart_serv" disabled>Recharger la page</button>
        </div>
      </div>

      <QuizzList/>

      

    </div>

  </div>
</template>

<script>
import QuizzCreate from '@/components/admin/QuizzCreate.vue'
import QuizzList from '@/components/admin/QuizzList.vue'
import UpdateLobbyPicture from '@/components/admin/UpdateLobbyPicture.vue'
import PointsModify from '@/components/admin/PointsModify.vue'

export default {
  name: 'QuizzManager',
  components: {
    QuizzCreate,
    QuizzList,
    UpdateLobbyPicture,
    PointsModify
  },
  data () {
    return {
      show: "home",

      roomID: null,

      restart_loading: false
    }
  },
  props: {
    google: Object,
  },
  methods: {
    showCreate(e) {
      e.preventDefault();
      this.show = "create"
    },
    createClose() {
      this.show = "home"
    },
    showModifyPoints(e){
      e.preventDefault();
      this.show = "modifyPoints";
    },
    modifyPointsClose(){
      this.show = "home";
    },
    showUpdateLobbyPic(e) {
      e.preventDefault();
      this.show = "updateLobbyPic"
    },
    updateLobbyPicClose() {
      this.show = "home"
    },
    restart_serv(e) {
      e.preventDefault();
      this.restart_loading = true;

      const axios = require('axios');
      axios({
        method: "get",
        url: this.$store.state.serverIp + '/api/pres/restart',
        headers: {
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },
      }).then(function() {

        self.loading = false;
        self.$emit('deleted');
        
      }).catch(function(thrown) {

        console.log(thrown); 
        self.loading = false;

      });
    }
    // newRoom(e) {
    //   e.preventDefault();
    //   this.$socket.emit('pres_newRoom', this.$store.state.login_token)
    // }
  }

}
</script>