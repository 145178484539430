<template>
  <div>
    
    <div class="grid-container --margin-left --margin-top">
        <div class="flex-container">
    
          <div>QuizzCreate</div>
      
          <p v-if="error">{{ error }}</p>
          <button v-if="!loading" @click="cancel_btn">Annuler</button>
          <button v-if="loading" disabled>...</button>
        
        </div>
    
        <div v-if="step=='title'">
          <form @submit="submit_titleForm">
            <div class="flex-container">
    
                <div class="grid-container">
                  <input type="text" placeholder="Titre" name="name" v-model="titleForm.name" required>
                  <textarea placeholder="Description" name="description" v-model="titleForm.description" required></textarea>
                </div>
        
                <button v-if="!loading" type="submit">Suivant</button>
                <button v-if="loading" type="submit" disabled>...</button>
            
            </div>
          </form>
        </div>
    </div>

    <div v-if="step=='questions'">

      <div class="flex-container">
          <div>
            Création de : {{ quizzData.name }} ({{ quizzData.unique_name }})
          </div>
    
          <select name="options" id="selectOptions" v-model="selected">
            <option value='' selected disabled hidden> Selectioner type d'input </option>
            <option value="url">Google Maps streetview URL</option>
            <option value="manual">Photo et coordonnées</option>
          </select>
      </div>



      
      <div v-if="selected == 'url'" class="grid-container">
        <span>Envoyer un lien google maps streetview</span>
        
          <form @submit="newQuestionForm_byURL">
            <div class="flex-container">
              <!-- <div class="input-items">
                <span>Titre: </span>
                <input type="text" placeholder="Titre" name="name" v-model="newQuestionForm.name" required>
              </div> -->
            
              <div class="grid-container">

                <div class="input-items">
                  <span>URL StreetView: </span>
                  <input type="url" placeholder="URL Streetview" name="uploadURL" v-model="newQuestionForm.url" required>
                </div>
                <div class="input-items small-gap">
                  <span>URL Incorporé: </span>
                  <input type="text" placeholder="Incorporé URL" name="uploadEmbeddedURL" v-model="newQuestionForm.embeddedUrl" required>
                </div>
              </div>
            
            <button type="submit">Envoyer URL</button>
            </div>
          </form>


      </div>

      <form v-if="selected == 'manual'" @submit="submit_newQuestionForm">

        

        <input type="file" name="uploadFile" accept="image/*" ref="newQuestionForm_fileinput" @change="newFile_newQuestionForm" require>
        <img id="preview_newQuestionForm" :src="newQuestionForm.preview_url" width="250"/>
        <input type="text" placeholder="Titre" name="name" v-model="newQuestionForm.name" required>

        <input type="text" placeholder="Coordonnées" name="coords" v-model="newQuestionForm.coords" required>
        Format attendu: <code>lat,lng | 00.0000,00.0000</code>

        <button @click="newQuestionForm_seeCoords">Voir sur la carte</button>
        <CreationMap v-if="newQuestionForm.coords_validated" :google="google" :step="'newQuestion'" :pin="newQuestionForm.coords_validated"/>

        <button v-if="!loading" type="submit">Ajouter</button>
        <button v-if="loading" type="submit" disabled>{{ newQuestionForm.progress }}</button>

      </form>

      <div class="flex-container">
        <button @click="refreshQuestion_btn">Actualiser questions</button>
        <button @click="questions_next" v-if="!loading">Suivant</button>
        <button v-if="loading" disabled>...</button>
      </div>

      <div v-if="selected == 'manual'">
        <div v-for="question in quizzData.questions" :key="question.filename" class="grid-container">
            <Question
              :filename="question.filename" 
              :name="question.name" 
              :coords="question.coords" 
              :quizz_unique_name="quizzData.unique_name"
              @deleted="refreshQuestions"
            />
        </div>
      </div>
      <div v-else-if="selected == 'url'" class="grid-container">
        <div v-for="question in quizzData.questions" :key="question.name" >
           {{ question.name }}; {{ question.coords }}; {{ question.url }}; {{ quizzData.unique_name }};
        </div>
      </div>


    </div>

    <div v-if="step=='end'">  

      <CreationMap :google="google" :step="'initQuizz'" :questions="quizzData.questions" @finishInit="submit_mapSettings"/>

      <!-- <button v-if="!loading">Terminer</button>
      <button v-if="loading" disabled>...</button> -->
    </div>

  </div>
</template>

<style scoped>

input{
  max-height: 2em;
}

button{
  max-height: 2em;
  border-radius: .75rem;
  margin-left: .25rem;
}

.debug{
  outline: red solid 4px;
}
.flex-container{
  display: flex;
  gap: 2em; 
  flex-wrap: wrap;
  align-items: center;
}
.input-items{
  display: flex;
  gap: .5em;
}

.grid-container{
  display: grid;
  gap: 1em;
  margin-bottom: 2em;
}

.small-gap{
  gap: 1em;
}

.--margin-left{
  margin-left: 2em;
}
.--margin-top{
  margin-top: 2em;
}

</style>

<script>
import Question from '@/components/admin/quizzCreation/Question.vue'
import CreationMap from '@/components/admin/quizzCreation/CreationMap.vue'

export default {
  name: 'QuizzCreate',
  components: {
    Question,
    CreationMap
  },
  data () {
    return {
      selected: '',
      step: "title",

      loading: false,
      error: '',

      quizzData: {
        unique_name: null,

        name: null,
        description: null,

        questions: []
      },

      titleForm: {
        name: null,
        description: null
      },

      newQuestionForm: {
        url: null,
        embeddedUrl: null,
        file: null,

        preview_url: null,

        name: null,
        coords: null,

        coords_validated: null,

        progress: 0
      },
    }
  },
  props: {
    google: Object,
  },
  methods: {
    submit_titleForm(e) {
      e.preventDefault();
      
      this.loading = true;
      this.error = '';

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json" ,
          'Authorization': `Bearer ${this.$store.state.login_token}`,
          },
        body: JSON.stringify({
          name: this.titleForm.name,
          description: this.titleForm.description
        })
      };

      var self = this;

      fetch(this.$store.state.serverIp + '/api/pres/quizz/init', requestOptions)
      .then(async function (response) {

        self.loading = false;

        switch (response.status) {
          case 201:
            var unique_name = await response.text();

            self.quizzData.name = self.titleForm.name;
            self.quizzData.description = self.titleForm.description;
            self.quizzData.unique_name = unique_name;

            self.step = 'questions'

            break;
          case 401:
            self.error = "Non authentifié";
            break;
          case 403:
            self.error = "Authentification incorrecte";
            break;
          default:
            self.error = "Une erreur est survenue au niveau du serveur";
            break;
        }
      });

    },

    newFile_newQuestionForm(e) {
      e.preventDefault();

      this.newQuestionForm.file = e.target.files[0];   
      this.newQuestionForm.name = this.newQuestionForm.file.name.split('.').slice(0, -1).join('.');

      // var preview = document.getElementById('preview_newQuestionForm');
      this.newQuestionForm.preview_url = URL.createObjectURL(this.newQuestionForm.file);
    },

    submit_newQuestionForm(e) {
      e.preventDefault();

      if (!this.newQuestionForm.coords) {
        this.error = 'Merci de renseigner des coordonnées'
        return null
      }

      const coords = this.newQuestionForm_stringToCoords(this.newQuestionForm.coords);

      if (!coords) {
        this.error = 'Coordonnées invalides'
        return null
      }

      const axios = require('axios');

      var formdata = new FormData();
      formdata.append("uploadFile", this.newQuestionForm.file );
      formdata.set("name", this.newQuestionForm.name);
      formdata.set("coords",JSON.stringify(coords));

      var self = this;

      this.loading = true;

      this.uploadRequest = axios({

        method: "post",
        data: formdata,

        url: this.$store.state.serverIp + '/api/pres/quizz/question/new/' + this.quizzData.unique_name,
        headers: {
          'Content-Type': 'multipart/form-data' ,
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },

        onUploadProgress: function(event) {
          self.newQuestionForm.progress = Math.round((event.loaded/event.total)*100);
        },

      }).then(function(response) {

        console.log("ajouté");
        console.log(response);

        self.newQuestionForm = {
          url: null,
          embeddedUrl: null,
          file: null,

          preview_url: null,

          name: null,
          coords: null,

          coords_validated: null,

          progress: 0
        }

        self.refreshQuestions();
        self.$refs.newQuestionForm_fileinput.value = null;
        self.loading = false;
        
      }).catch(function(thrown) {

        console.log("Erreur lors de l'envoi");
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          console.log(thrown); 
        }

        self.loading = false;
      })
    },

    newQuestionForm_byURL(e){
      e.preventDefault();

      const axios = require('axios');

      let url = this.newQuestionForm.url;
      let embeddedUrl = this.newQuestionForm.embeddedUrl.replace('style="border:0','');
      console.log("EMBEDDED:::", embeddedUrl);
      this.newQuestionForm.embeddedUrl = embeddedUrl;
      let coords = {};
      let formdata = new FormData();

      if (url == null || embeddedUrl == null){
        return null;
      }
      

      coords['lat'] = parseFloat(url.split('@')[1].split(",")[0])
      coords['lng'] = parseFloat(url.split('@')[1].split(",")[1])

      formdata.set("coords",JSON.stringify(coords));
      formdata.set("name", this.newQuestionForm.name);
      formdata.set("url", embeddedUrl);

      /* */
      var self = this;

      this.loading = true;

      this.uploadRequest = axios({

        method: "post",
        data: formdata,

        url: this.$store.state.serverIp + '/api/pres/quizz/question/new/' + this.quizzData.unique_name,
        headers: {
          'Content-Type': 'multipart/form-data' ,
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },

        onUploadProgress: function(event) {
          self.newQuestionForm.progress = Math.round((event.loaded/event.total)*100);
        },

      }).then(function(response) {

        console.log("ajouté");
        console.log(response);

        self.newQuestionForm = {
          url: null,
          embeddedUrl: null,
          file: null,

          preview_url: null,

          name: null,
          coords: null,

          coords_validated: null,

          progress: 0
        }

        self.refreshQuestions();
        self.$refs.newQuestionForm_fileinput.value = null;
        self.loading = false;
        
      }).catch(function(thrown) {

        console.log("Erreur lors de l'envoi");
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          console.log(thrown); 
        }

        self.loading = false;
      })
      /* */
    },

    newQuestionForm_stringToCoords(s) {
      const coordsArray = s.replace(/\s/g, '').split(',')
      if (coordsArray.length  == 2) {
        return {
          lat: parseFloat(coordsArray[0]),
          lng: parseFloat(coordsArray[1]),
        }
      } else {
        return false
      }
    },


    newQuestionForm_seeCoords(e) {
      e.preventDefault();

      const coords = this.newQuestionForm_stringToCoords(this.newQuestionForm.coords);

      if (coords) {
        this.newQuestionForm.coords_validated = coords;
      } else {
        this.error = 'Impossible de détecter des coordonnées'
      }    
    },

    refreshQuestion_btn(e) {
      e.preventDefault();

      this.refreshQuestions();
    },

    refreshQuestions() {

      const axios = require('axios');

      var self = this;
      axios({
        method: "get",
        url: `${this.$store.state.serverIp}/api/pres/quizz/get/${this.quizzData.unique_name}`,
        headers: {
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },
      }).then(function(response) {

        self.quizzData.questions = response.data.questions;
        self.loading = false;
        
      }).catch(function(thrown) {

        console.log(thrown); 
        self.loading = false;
        self.error = thrown

      });
    },

    questions_next(e) {
      e.preventDefault();      
      
      const requestOptions = {
        method: "GET",
        headers: {
          // "Content-Type": "application/json" ,
          'Authorization': `Bearer ${this.$store.state.login_token}`,
        },
      };

      var self = this;

      fetch(`${this.$store.state.serverIp}/api/pres/quizz/get/${this.quizzData.unique_name}`, requestOptions)
      .then(async function (response) {

        self.loading = false;

        switch (response.status) {
          case 200:
            var quizz = await response.json();
            self.quizzData.questions = quizz.questions;
            if (self.quizzData.questions.length > 0) {
              self.step = 'end'; 
            } else {
              self.error = "Définir au moins une question"
            }
            
            break;
          case 401:
            self.error = "Non authentifié";
            break;
          case 403:
            self.error = "Authentification incorrecte";
            break;
          default:
            self.error = "Une erreur est survenue au niveau du serveur";
            break;
        }
      });

      

      

      
    },

    submit_mapSettings(mapSettings) {

      this.loading = true;
      this.error = '';

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json" ,
          'Authorization': `Bearer ${this.$store.state.login_token}`,
          },
        body: JSON.stringify({
          mapSettings: mapSettings
        })
      };

      var self = this;

      fetch(`${this.$store.state.serverIp}/api/pres/quizz/mapSettings/${this.quizzData.unique_name}`, requestOptions)
      .then(async function (response) {

        self.loading = false;

        switch (response.status) {
          case 200:
            console.log('Finish')
            self.$emit('close')
            break;
          case 401:
            self.error = "Non authentifié";
            break;
          case 403:
            self.error = "Authentification incorrecte";
            break;
          default:
            self.error = "Une erreur est survenue au niveau du serveur";
            break;
        }
      });
    },

    cancel_btn(e) {
      e.preventDefault();
      if (this.step == "title") {

        this.$emit('close');

      } else {
        
        this.step = "canceling"
        
        this.loading = true;

        const axios = require('axios');

        var self = this;
        axios({
          method: "get",
          url: this.$store.state.serverIp + '/api/pres/quizz/delete/' + this.quizzData.unique_name,
          headers: {
            'Authorization': `Bearer ${this.$store.state.login_token}`,
          },
        }).then(function() {

          self.loading = false;
          self.$emit('close');
          
        }).catch(function(thrown) {

          console.log(thrown); 
          self.loading = false;

        });
      }

    }
  }
}
</script>