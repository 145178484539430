<template>
  <div style="width: 100%; height: 100%">


    <div v-if="show == 'loading'" class="container d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
      <h1 class="text-center">Chargement</h1><span class="spinner-grow textprimary text-primary" role="status"></span>
    </div>

    <div v-if="show == 'lobby'" class="container d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
      <h1 class="text-center">Salon d'attente</h1>
      <div class="text-center" style="width: 100%;">
          <p class="border rounded-pill border-primary">Vous êtes <span class="text-primary">{{pseudo}}</span> <img :src="pseudoLogoUrl"></p>
          <p>Pour jouer, utilisez la carte qui apparaîtra sur votre télephone et cliquez à l'endroit de votre supposition. Envoyez votre réponse en cliquant sur le bouton Répondre.</p>
          <p>Plus vous êtes precis et rapide, plus vous marquez des points.</p>
      </div>
      <p style="margin-top: 20px;">En attente du lancement de la partie<span class="spinner-border spinner-border-sm" role="status" style="margin-left: 10px;"></span></p>
    </div>

    <div v-if="show == 'game'" style="width: 100%; height: 100%">

      <!-- <div style="position: relative;top: 75px;">
        Vous êtes {{pseudo}}
      </div> -->

      <div v-if="step == 'starting'" class="container d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
        <h1 class="text-center">Lancement</h1><span class="spinner-grow textprimary text-primary" role="status"></span>
      </div>

      <div v-if="step == 'question'" style="width: 100%; height: 100%">
        <Question :google="google" :mapSettings="mapSettings" :pointsSettings="pointsSettings" :time="questionTime" :pseudo="pseudo" @answer="answer" style="width: 100%; height: 100%"/>
      </div>



      <!-- <div v-if="step == 'answered'">answer
        {{tempsReponse}}s | +{{pointsQuestion}} | {{pointsTotal}}
      </div> -->

      <div v-if="step == 'answered'" class="container d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
        <h1 class="text-center">Réponse envoyée !</h1>
        <p class="text-center border rounded-pill border-primary">Vous êtes <span class="text-primary">{{pseudo}}</span> <img :src="pseudoLogoUrl"></p>
        <p style="margin-bottom: 0px;"><em>Temps de réponse : {{tempsReponse}}</em></p>
        <p style="margin-top: 20px;">En attente des autres joueurs<span class="spinner-border spinner-border-sm" role="status" style="margin-left: 10px;"></span></p>
      </div>

      <div v-if="step == 'endQuestion'" class="container d-flex flex-column justify-content-center align-items-center" style="height: 100%;">
        <h1 class="text-center">Résultats de la question</h1>
        <p v-if="distanceQuestion" style="margin-bottom: 0px;"><span class="text-primary">{{pseudo}}</span> <img :src="pseudoLogoUrl"> vous êtes à {{distanceQuestion}}m !</p>
        <p v-if="pointsQuestion > 0" style="margin-bottom: 0px;">Vous marquez {{pointsQuestion}} points</p>
        <p v-if="pointsQuestion == 0" style="margin-bottom: 0px;">Vous ne marquez pas de points</p>
        <p v-if="position" style="margin-bottom: 0px;"><strong>Position : </strong>#{{position}} <span v-if="position==1">🥇</span><span v-if="position==2">🥈</span><span v-if="position==3">🥉</span></p>
        <p><strong>Total : </strong>{{pointsTotal}} points</p>
        <p style="margin-top: 20px;">La prochaine question arrive<span class="spinner-border spinner-border-sm" role="status" style="margin-left: 10px;"></span></p>
      </div>      

      <div v-if="step == 'endGame'" class="container d-flex flex-column" style="height: 100%;">
        <div class="row">
          <div class="col d-flex flex-column align-items-center" style="margin-top: 30vh;margin-bottom: 50px;">
            <h1 class="text-center">Fin de la partie</h1>
            <p style="margin-bottom: 0px;"><strong>Position : </strong>#{{position}} <span v-if="position==1">🥇</span><span v-if="position==2">🥈</span><span v-if="position==3">🥉</span></p>
            <p><strong>Total : </strong>{{pointsTotal}} points</p>
            <br>
            <p><em>Faites défiler le classement pour voir votre position</em></p>
          </div>
        </div>
        <div class="row flex-grow-1">
          <div class="col">


            <div v-for="(res, index) in results" :key="index" class="row" style="margin-bottom: 10px;">
              <div class="col">
                <div class="border rounded border-primary" v-if="res.pseudo != pseudo">
                  <p style="margin: 5px 10px;"><strong>#{{index + 1}}</strong> {{res.pseudo}} <img :src="res.pseudoLogoUrl"> <span class="badge bg-primary">{{res.points}} points</span></p>
                </div>
                <div class="border rounded border-danger" v-if="res.pseudo == pseudo">
                  <p style="margin: 5px 10px;"><strong>#{{index + 1}} {{res.pseudo}}</strong> <img :src="res.pseudoLogoUrl"> <span class="badge bg-primary">{{res.points}} points</span></p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import {Loader} from 'google-maps';
// const loader = new Loader('AIzaSyBmBMqdz2WB59Hzgpyw-GSSjXQqY31WIVA');
const loader = new Loader('AIzaSyBPOI9ZRAErZFh129HNBt3JSZhMmyB0580');

// import Lobby from '@/components/game/Lobby.vue'
import Question from '@/components/game/Question.vue'

export default {
  name: 'Game',
  components: {
    // Lobby,
    Question
  },
  data () {
    return {
      show: 'loading',
      loading: false,
      error: '',
      // askPseudo: null,
      mapSettings: null,
      pointsSettings: null,

      pseudo: null,

      step: 'starting',

      questionStartTime: null,
      questionTime: null,

      tempsReponse: null,
      pointsQuestion: null,
      pointsTotal: 0,
      distanceQuestion: 0,

      position: 0,

      google: null,

      results: [],
    }
  },
  computed: {
    pseudoLogoUrl() {
      return `${this.$store.state.serverIp}/api/markers/${this.pseudo.replace(/\s+/g, '')}`;
    }
  },
  mounted: async function() {

    if (this.$store.state.game.roomID) {
      this.joinRoom_socket(this.$store.state.game.roomID)
    } else {
      if (await this.checkRoom()) {
        // this.show = 'askPseudo';
        this.$store.commit('JOIN_ROOM', {
          roomID: this.$route.params.roomID,
          // pseudo: this.askPseudo
        });
        this.joinRoom_socket(this.$store.state.game.roomID);
      } else {
        this.$router.push('/', () => {
          console.log('oui'); 
          location.reload()
        });
      }
    }

    const google = await loader.load();
    this.google = google;

  },
  sockets: {
    connect: function () {
      console.log('socket connected')
    },
    /**
     * LOBBY
     */
    player_joinRoom_callback: function (data) {
      console.log('joined');
      // console.log(data.pseudo);
      this.pseudo = data.pseudo
      this.mapSettings = data.mapSettings;
      this.pointsSettings = data.pointsSettings;
      console.log(this.pointsSettings)
      this.show = 'lobby';
    },
    player_kicked: function (mode) {
      if (mode == 'kick') {
        console.log('kicked');
        this.show = 'askPseudo';
      } else if (mode == 'presLeft') {
        console.log('presLeft');
        this.$router.push('/');
      } else {
        console.log('ended');
        this.$router.push('/');
      }
    },

    /**
     * GAME LOOP
     */
    player_startGame: function () {
      console.log('Start game');
      this.show = 'game';
    },                                             
    player_newQuestion: function (data) {
      console.log('Nouvelle question !');
      console.log(data);
      this.questionStartTime = Date.now()
      this.questionTime = data.time*1000;

      this.step = 'question';

      this.tempsReponse = null
      this.pointsQuestion = null
    },
    player_answer_callback: function (data) {
      console.log('Réponse reçue par le serveur');
      this.step = 'answered';

      this.tempsReponse = data.tempsReponse.toFixed(2)
      this.pointsQuestion = data.pointsQuestion.toFixed(2)
      this.pointsTotal = data.pointsTotal.toFixed(2)

    },
    player_endQuestion: function (data) {
      console.log('Fin de la question');
      this.step = 'endQuestion';

      this.pointsTotal = data.pointsTotal.toFixed(2)
      if (data.distance != null) this.distanceQuestion = data.distance.toFixed(2)
      else this.distanceQuestion = null;

      const results = data.results;
      for (let i = 0; i < results.length; i++) {
        const res = results[i];
        if (res.pseudo == this.pseudo) {
          this.position = i + 1;
        }
      }
    },
    player_endGame: function (results) {
      console.log('Fin de la partie');

      this.results = results;


      for (let i = 0; i < results.length; i++) {
        const res = results[i];
        if (res.pseudo == this.pseudo) {
          this.position = i + 1;
        }
        results[i].pseudoLogoUrl = `${this.$store.state.serverIp}/api/markers/${res.pseudo.replace(/\s+/g, '')}`
      }

      this.step = 'endGame';
    },
  },
  methods: {
    // async askPseudoForm(e) {
    //   e.preventDefault();

    //   this.loading = true;
    //   this.error = '';

    //   if (await this.checkRoom()) {
    //     this.$store.commit('JOIN_ROOM', {
    //       roomID: this.$route.params.roomID,
    //       pseudo: this.askPseudo
    //     });
    //     this.joinRoom_socket(this.$route.params.roomID, this.askPseudo);
    //   }
    // },

    checkRoom() {
      const requestOptions = {
        method: "GET",
      };

      var self = this;

      return fetch(this.$store.state.serverIp + '/api/game/check/' + this.$route.params.roomID, requestOptions)
      .then(function (response) {

        self.loading = false;

        switch (response.status) {
          case 200:
            console.log('room joinable');
            return true;

          case 404:
            self.error = "La salle n'est plus disponible";
            return false;

          default:
            self.error = "Une erreur est survenue au niveau du serveur";
            return false;
        }
      });
    },

    joinRoom_socket(roomID) {
      this.$socket.emit('player_joinRoom', roomID);
    },

    answer(coords, points) {
      console.log(coords);
      const time = Date.now() - this.questionStartTime;
      console.log(time);
      this.$socket.emit('player_answer', {
        coords: coords,
        time: time,
        timePoints: points
      });
    }
  }
}
</script>

<style>
  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
  }
</style>