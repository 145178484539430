import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../views/admin/Admin.vue'
import Game from '../views/game/Game.vue'

//Temp
import Map from '../components/game/Map.vue'

const routes = [
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pres',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/pres/:quizz_unique_name',
    name: 'Admin_Game_DefaultMaxPlayers',
    component: Admin
  },
  {
    path: '/pres/:quizz_unique_name/:maxPlayers',
    name: 'Admin_Game',
    component: Admin
  },
  {
    path: '/play/:roomID',
    name: 'Game',
    component: Game
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
